import './Smoke.css';
import { useRef, useEffect } from 'react';

let config = {
    SIM_RESOLUTION: 128,
    DYE_RESOLUTION: 1024,
    DENSITY_DISSIPATION: 0.8,
    VELOCITY_DISSIPATION: 0.35,
    PRESSURE: 0.1,
    PRESSURE_ITERATIONS: 1,
    CURL: 1.2,
    SPLAT_RADIUS: 0.02,
    SPLAT_FORCE: 1800,
    SHADING: true,
}

function Smoke() {
    const canvasRef = useRef(null);
    const rendering = useRef(false);

    useEffect(() => {
        if (rendering.current) return;

        rendering.current = true;
        const canvas = canvasRef.current;

        resizeCanvas();

        class Particle {
            constructor() {
                this.id = -1;
                this.texcoordX = 0.5;
                this.texcoordY = 1.0;
                this.prevTexcoordX = 0.5;
                this.prevTexcoordY = 1.0;
                this.deltaX = 0;
                this.deltaY = 0;
                this.moved = false;
                this.decaying = false;
                this.color = [30, 0, 300];
            }
        }
        
        let particles = [];
        particles.push(new Particle());
        
        const { gl, ext } = getWebGLContext(canvas);
        
        if (isMobile()) {
            config.DYE_RESOLUTION = 512;
        }

        if (!ext.supportLinearFiltering) {
            config.DYE_RESOLUTION = 512;
            config.SHADING = false;
        }

        function isMobile () {
            return /Mobi|Android/i.test(navigator.userAgent);
        }
        
        function getWebGLContext (canvas) {
            const params = { alpha: true, depth: false, stencil: false, antialias: false, preserveDrawingBuffer: false, premultipliedAlpha: true };
        
            let gl = canvas.getContext('webgl2', params);
            const isWebGL2 = !!gl;
            if (!isWebGL2)
                gl = canvas.getContext('webgl', params) || canvas.getContext('experimental-webgl', params);
        
            let halfFloat;
            let supportLinearFiltering;
            if (isWebGL2) {
                gl.getExtension('EXT_color_buffer_float');
                supportLinearFiltering = gl.getExtension('OES_texture_float_linear');
            } else {
                halfFloat = gl.getExtension('OES_texture_half_float');
                supportLinearFiltering = gl.getExtension('OES_texture_half_float_linear');
            }
        
            gl.clearColor(0.0, 0.0, 0.0, 1.0);
        
            const halfFloatTexType = isWebGL2 ? gl.HALF_FLOAT : halfFloat.HALF_FLOAT_OES;
            let formatRGBA;
            let formatRG;
            let formatR;
        
            if (isWebGL2) {
                formatRGBA = getSupportedFormat(gl, gl.RGBA16F, gl.RGBA, halfFloatTexType);
                formatRG = getSupportedFormat(gl, gl.RG16F, gl.RG, halfFloatTexType);
                formatR = getSupportedFormat(gl, gl.R16F, gl.RED, halfFloatTexType);
            } else {
                formatRGBA = getSupportedFormat(gl, gl.RGBA, gl.RGBA, halfFloatTexType);
                formatRG = getSupportedFormat(gl, gl.RGBA, gl.RGBA, halfFloatTexType);
                formatR = getSupportedFormat(gl, gl.RGBA, gl.RGBA, halfFloatTexType);
            }
        
            return {
                gl,
                ext: {
                    formatRGBA,
                    formatRG,
                    formatR,
                    halfFloatTexType,
                    supportLinearFiltering
                }
            };
        }
        
        function getSupportedFormat(gl, internalFormat, format, type)
        {
            if (!supportRenderTextureFormat(gl, internalFormat, format, type))
            {
                switch (internalFormat)
                {
                    case gl.R16F:
                        return getSupportedFormat(gl, gl.RG16F, gl.RG, type);
                    case gl.RG16F:
                        return getSupportedFormat(gl, gl.RGBA16F, gl.RGBA, type);
                    default:
                        return null;
                }
            }
        
            return {
                internalFormat,
                format
            }
        }
        
        function supportRenderTextureFormat(gl, internalFormat, format, type) {
            let texture = gl.createTexture();
            gl.bindTexture(gl.TEXTURE_2D, texture);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
            gl.texImage2D(gl.TEXTURE_2D, 0, internalFormat, 4, 4, 0, format, type, null);
        
            let fbo = gl.createFramebuffer();
            gl.bindFramebuffer(gl.FRAMEBUFFER, fbo);
            gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
        
            let status = gl.checkFramebufferStatus(gl.FRAMEBUFFER);
            return status === gl.FRAMEBUFFER_COMPLETE;
        }
        
        class Material {
            constructor (vertexShader, fragmentShaderSource) {
                this.vertexShader = vertexShader;
                this.fragmentShaderSource = fragmentShaderSource;
                this.shadingProgram = null;
                this.uniforms = [];
            }
        
            enableShading() {
                if (this.shadingProgram == null)
                {
                    let fragmentShader = compileShader(gl.FRAGMENT_SHADER, this.fragmentShaderSource, ['SHADING']);
                    this.shadingProgram = createProgram(this.vertexShader, fragmentShader);
                    this.uniforms = getUniforms(this.shadingProgram);
                }
            }
        
            bind () {
                gl.useProgram(this.shadingProgram);
            }
        }
        
        class Program {
            constructor (vertexShader, fragmentShader) {
                this.uniforms = {};
                this.program = createProgram(vertexShader, fragmentShader);
                this.uniforms = getUniforms(this.program);
            }
        
            bind () {
                gl.useProgram(this.program);
            }
        }
        
        function createProgram(vertexShader, fragmentShader) {
            let program = gl.createProgram();
            gl.attachShader(program, vertexShader);
            gl.attachShader(program, fragmentShader);
            gl.linkProgram(program);
        
            if (!gl.getProgramParameter(program, gl.LINK_STATUS))
                console.trace(gl.getProgramInfoLog(program));
        
            return program;
        }
        
        function getUniforms(program) {
            let uniforms = [];
            let uniformCount = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS);
            for (let i = 0; i < uniformCount; i++) {
                let uniformName = gl.getActiveUniform(program, i).name;
                uniforms[uniformName] = gl.getUniformLocation(program, uniformName);
            }
            return uniforms;
        }
        
        function compileShader(type, source, keywords) {
            source = addKeywords(source, keywords);
        
            const shader = gl.createShader(type);
            gl.shaderSource(shader, source);
            gl.compileShader(shader);
        
            if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS))
                console.trace(gl.getShaderInfoLog(shader));
        
            return shader;
        };
        
        function addKeywords(source, keywords) {
            if (keywords == null) return source;
            let keywordsString = '';
            keywords.forEach(keyword => {
                keywordsString += '#define ' + keyword + '\n';
            });
            return keywordsString + source;
        }
        
        const baseVertexShader = compileShader(gl.VERTEX_SHADER, `
            precision highp float;
        
            attribute vec2 aPosition;
            varying vec2 vUv;
            varying vec2 vL;
            varying vec2 vR;
            varying vec2 vT;
            varying vec2 vB;
            uniform vec2 texelSize;
        
            void main () {
                vUv = aPosition * 0.5 + 0.5;
                vL = vUv - vec2(texelSize.x, 0.0);
                vR = vUv + vec2(texelSize.x, 0.0);
                vT = vUv + vec2(0.0, texelSize.y);
                vB = vUv - vec2(0.0, texelSize.y);
                gl_Position = vec4(aPosition, 0.0, 1.0);
            }
        `);
        
        const copyShader = compileShader(gl.FRAGMENT_SHADER, `
            precision mediump float;
            precision mediump sampler2D;
        
            varying highp vec2 vUv;
            uniform sampler2D uTexture;
        
            void main () {
                gl_FragColor = texture2D(uTexture, vUv);
            }
        `);
        
        const clearShader = compileShader(gl.FRAGMENT_SHADER, `
            precision mediump float;
            precision mediump sampler2D;
        
            varying highp vec2 vUv;
            uniform sampler2D uTexture;
            uniform float value;
        
            void main () {
                gl_FragColor = value * texture2D(uTexture, vUv);
            }
        `);
        
        const displayShaderSource = `
            precision highp float;
            precision highp sampler2D;
        
            varying vec2 vUv;
            varying vec2 vL;
            varying vec2 vR;
            varying vec2 vT;
            varying vec2 vB;
            uniform sampler2D uTexture;
            uniform sampler2D uDithering;
            uniform vec2 ditherScale;
            uniform vec2 texelSize;
        
            vec3 linearToGamma (vec3 color) {
                color = max(color, vec3(0));
                return max(1.055 * pow(color, vec3(0.416666667)) - 0.055, vec3(0));
            }
        
            void main () {
                vec3 c = texture2D(uTexture, vUv).rgb;
        
            #ifdef SHADING
                vec3 lc = texture2D(uTexture, vL).rgb;
                vec3 rc = texture2D(uTexture, vR).rgb;
                vec3 tc = texture2D(uTexture, vT).rgb;
                vec3 bc = texture2D(uTexture, vB).rgb;
        
                float dx = length(rc) - length(lc);
                float dy = length(tc) - length(bc);
        
                vec3 n = normalize(vec3(dx, dy, length(texelSize)));
                vec3 l = vec3(0.0, 0.0, 1.0);
        
                float diffuse = clamp(dot(n, l) + 0.7, 0.7, 1.0);
                c *= diffuse;
            #endif
        
                float a = max(c.r, max(c.g, c.b));
                gl_FragColor = vec4(c, a);
            }
        `;
        
        const splatShader = compileShader(gl.FRAGMENT_SHADER, `
            precision highp float;
            precision highp sampler2D;
        
            varying vec2 vUv;
            uniform sampler2D uTarget;
            uniform float aspectRatio;
            uniform vec3 color;
            uniform vec2 point;
            uniform float radius;
        
            void main () {
                vec2 p = vUv - point.xy;
                p.x *= aspectRatio;
                vec3 splat = exp(-dot(p, p) / radius) * color;
                vec3 base = texture2D(uTarget, vUv).xyz;
                gl_FragColor = vec4(base + splat, 1.0);
            }
        `);
        
        const advectionShader = compileShader(gl.FRAGMENT_SHADER, `
            precision highp float;
            precision highp sampler2D;
        
            varying vec2 vUv;
            uniform sampler2D uVelocity;
            uniform sampler2D uSource;
            uniform vec2 texelSize;
            uniform vec2 dyeTexelSize;
            uniform float dt;
            uniform float dissipation;
        
            vec4 bilerp (sampler2D sam, vec2 uv, vec2 tsize) {
                vec2 st = uv / tsize - 0.5;
        
                vec2 iuv = floor(st);
                vec2 fuv = fract(st);
        
                vec4 a = texture2D(sam, (iuv + vec2(0.5, 0.5)) * tsize);
                vec4 b = texture2D(sam, (iuv + vec2(1.5, 0.5)) * tsize);
                vec4 c = texture2D(sam, (iuv + vec2(0.5, 1.5)) * tsize);
                vec4 d = texture2D(sam, (iuv + vec2(1.5, 1.5)) * tsize);
        
                return mix(mix(a, b, fuv.x), mix(c, d, fuv.x), fuv.y);
            }
        
            void main () {
            #ifdef MANUAL_FILTERING
                vec2 coord = vUv - dt * bilerp(uVelocity, vUv, texelSize).xy * texelSize;
                vec4 result = bilerp(uSource, coord, dyeTexelSize);
            #else
                vec2 coord = vUv - dt * texture2D(uVelocity, vUv).xy * texelSize;
                vec4 result = texture2D(uSource, coord);
            #endif
                float decay = 1.0 + dissipation * dt;
                gl_FragColor = result / decay;
            }`,
            ext.supportLinearFiltering ? null : ['MANUAL_FILTERING']
        );
        
        const divergenceShader = compileShader(gl.FRAGMENT_SHADER, `
            precision mediump float;
            precision mediump sampler2D;
        
            varying highp vec2 vUv;
            varying highp vec2 vL;
            varying highp vec2 vR;
            varying highp vec2 vT;
            varying highp vec2 vB;
            uniform sampler2D uVelocity;
        
            void main () {
                float L = texture2D(uVelocity, vL).x;
                float R = texture2D(uVelocity, vR).x;
                float T = texture2D(uVelocity, vT).y;
                float B = texture2D(uVelocity, vB).y;
        
                vec2 C = texture2D(uVelocity, vUv).xy;
                if (vL.x < 0.0) { L = -C.x; }
                if (vR.x > 1.0) { R = -C.x; }
                if (vT.y > 1.0) { T = -C.y; }
                if (vB.y < 0.0) { B = -C.y; }
        
                float div = 0.5 * (R - L + T - B);
                gl_FragColor = vec4(div, 0.0, 0.0, 1.0);
            }
        `);
        
        const curlShader = compileShader(gl.FRAGMENT_SHADER, `
            precision mediump float;
            precision mediump sampler2D;
        
            varying highp vec2 vUv;
            varying highp vec2 vL;
            varying highp vec2 vR;
            varying highp vec2 vT;
            varying highp vec2 vB;
            uniform sampler2D uVelocity;
        
            void main () {
                float L = texture2D(uVelocity, vL).y;
                float R = texture2D(uVelocity, vR).y;
                float T = texture2D(uVelocity, vT).x;
                float B = texture2D(uVelocity, vB).x;
                float vorticity = R - L - T + B;
                gl_FragColor = vec4(0.5 * vorticity, 0.0, 0.0, 1.0);
            }
        `);
        
        const vorticityShader = compileShader(gl.FRAGMENT_SHADER, `
            precision highp float;
            precision highp sampler2D;
        
            varying vec2 vUv;
            varying vec2 vL;
            varying vec2 vR;
            varying vec2 vT;
            varying vec2 vB;
            uniform sampler2D uVelocity;
            uniform sampler2D uCurl;
            uniform float curl;
            uniform float dt;
        
            void main () {
                float L = texture2D(uCurl, vL).x;
                float R = texture2D(uCurl, vR).x;
                float T = texture2D(uCurl, vT).x;
                float B = texture2D(uCurl, vB).x;
                float C = texture2D(uCurl, vUv).x;
        
                vec2 force = 0.5 * vec2(abs(T) - abs(B), abs(R) - abs(L));
                force /= length(force) + 0.0001;
                force *= curl * C;
                force.y *= -1.0;
        
                vec2 velocity = texture2D(uVelocity, vUv).xy;
                velocity += force * dt;
                velocity = min(max(velocity, -1000.0), 1000.0);
                gl_FragColor = vec4(velocity, 0.0, 1.0);
            }
        `);
        
        const pressureShader = compileShader(gl.FRAGMENT_SHADER, `
            precision mediump float;
            precision mediump sampler2D;
        
            varying highp vec2 vUv;
            varying highp vec2 vL;
            varying highp vec2 vR;
            varying highp vec2 vT;
            varying highp vec2 vB;
            uniform sampler2D uPressure;
            uniform sampler2D uDivergence;
        
            void main () {
                float L = texture2D(uPressure, vL).x;
                float R = texture2D(uPressure, vR).x;
                float T = texture2D(uPressure, vT).x;
                float B = texture2D(uPressure, vB).x;
                float C = texture2D(uPressure, vUv).x;
                float divergence = texture2D(uDivergence, vUv).x;
                float pressure = (L + R + B + T - divergence) * 0.25;
                gl_FragColor = vec4(pressure, 0.0, 0.0, 1.0);
            }
        `);
        
        const gradientSubtractShader = compileShader(gl.FRAGMENT_SHADER, `
            precision mediump float;
            precision mediump sampler2D;
        
            varying highp vec2 vUv;
            varying highp vec2 vL;
            varying highp vec2 vR;
            varying highp vec2 vT;
            varying highp vec2 vB;
            uniform sampler2D uPressure;
            uniform sampler2D uVelocity;
        
            void main () {
                float L = texture2D(uPressure, vL).x;
                float R = texture2D(uPressure, vR).x;
                float T = texture2D(uPressure, vT).x;
                float B = texture2D(uPressure, vB).x;
                vec2 velocity = texture2D(uVelocity, vUv).xy;
                velocity.xy -= vec2(R - L, T - B);
                gl_FragColor = vec4(velocity, 0.0, 1.0);
            }
        `);
        
        const blit = (() => {
            gl.bindBuffer(gl.ARRAY_BUFFER, gl.createBuffer());
            gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([-1, -1, -1, 1, 1, 1, 1, -1]), gl.STATIC_DRAW);
            gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, gl.createBuffer());
            gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array([0, 1, 2, 0, 2, 3]), gl.STATIC_DRAW);
            gl.vertexAttribPointer(0, 2, gl.FLOAT, false, 0, 0);
            gl.enableVertexAttribArray(0);
        
            return (target = null, clear = false) => {
                if (target == null)
                {
                    gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
                    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
                }
                else
                {
                    gl.viewport(0, 0, target.width, target.height);
                    gl.bindFramebuffer(gl.FRAMEBUFFER, target.fbo);
                }
                if (clear)
                {
                    gl.clearColor(0.0, 0.0, 0.0, 1.0);
                    gl.clear(gl.COLOR_BUFFER_BIT);
                }
                gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);
            }
        })();
        
        let dye;
        let velocity;
        let divergence;
        let curl;
        let pressure;
        
        const copyProgram            = new Program(baseVertexShader, copyShader);
        const clearProgram           = new Program(baseVertexShader, clearShader);
        const splatProgram           = new Program(baseVertexShader, splatShader);
        const advectionProgram       = new Program(baseVertexShader, advectionShader);
        const divergenceProgram      = new Program(baseVertexShader, divergenceShader);
        const curlProgram            = new Program(baseVertexShader, curlShader);
        const vorticityProgram       = new Program(baseVertexShader, vorticityShader);
        const pressureProgram        = new Program(baseVertexShader, pressureShader);
        const gradientSubtractProgram = new Program(baseVertexShader, gradientSubtractShader);
        
        const displayMaterial = new Material(baseVertexShader, displayShaderSource);
        
        function initFramebuffers() {
            let simRes = getResolution(config.SIM_RESOLUTION);
            let dyeRes = getResolution(config.DYE_RESOLUTION);
        
            const texType = ext.halfFloatTexType;
            const rgba    = ext.formatRGBA;
            const rg      = ext.formatRG;
            const r       = ext.formatR;
            const filtering = ext.supportLinearFiltering ? gl.LINEAR : gl.NEAREST;
        
            gl.disable(gl.BLEND);
        
            if (dye == null)
                dye = createDoubleFBO(dyeRes.width, dyeRes.height, rgba.internalFormat, rgba.format, texType, filtering);
            else
                dye = resizeDoubleFBO(dye, dyeRes.width, dyeRes.height, rgba.internalFormat, rgba.format, texType, filtering);
        
            if (velocity == null)
                velocity = createDoubleFBO(simRes.width, simRes.height, rg.internalFormat, rg.format, texType, filtering);
            else
                velocity = resizeDoubleFBO(velocity, simRes.width, simRes.height, rg.internalFormat, rg.format, texType, filtering);
        
            divergence = createFBO      (simRes.width, simRes.height, r.internalFormat, r.format, texType, gl.NEAREST);
            curl       = createFBO      (simRes.width, simRes.height, r.internalFormat, r.format, texType, gl.NEAREST);
            pressure   = createDoubleFBO(simRes.width, simRes.height, r.internalFormat, r.format, texType, gl.NEAREST);
        }
        
        function createFBO (w, h, internalFormat, format, type, param) {
            gl.activeTexture(gl.TEXTURE0);
            let texture = gl.createTexture();
            gl.bindTexture(gl.TEXTURE_2D, texture);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, param);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, param);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
            gl.texImage2D(gl.TEXTURE_2D, 0, internalFormat, w, h, 0, format, type, null);
        
            let fbo = gl.createFramebuffer();
            gl.bindFramebuffer(gl.FRAMEBUFFER, fbo);
            gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
            gl.viewport(0, 0, w, h);
            gl.clear(gl.COLOR_BUFFER_BIT);
        
            let texelSizeX = 1.0 / w;
            let texelSizeY = 1.0 / h;
        
            return {
                texture,
                fbo,
                width: w,
                height: h,
                texelSizeX,
                texelSizeY,
                attach (id) {
                    gl.activeTexture(gl.TEXTURE0 + id);
                    gl.bindTexture(gl.TEXTURE_2D, texture);
                    return id;
                }
            };
        }
        
        function createDoubleFBO (w, h, internalFormat, format, type, param) {
            let fbo1 = createFBO(w, h, internalFormat, format, type, param);
            let fbo2 = createFBO(w, h, internalFormat, format, type, param);
        
            return {
                width: w,
                height: h,
                texelSizeX: fbo1.texelSizeX,
                texelSizeY: fbo1.texelSizeY,
                get read () {
                    return fbo1;
                },
                set read (value) {
                    fbo1 = value;
                },
                get write () {
                    return fbo2;
                },
                set write (value) {
                    fbo2 = value;
                },
                swap () {
                    let temp = fbo1;
                    fbo1 = fbo2;
                    fbo2 = temp;
                }
            }
        }
        
        function resizeFBO (target, w, h, internalFormat, format, type, param) {
            let newFBO = createFBO(w, h, internalFormat, format, type, param);
            copyProgram.bind();
            gl.uniform1i(copyProgram.uniforms.uTexture, target.attach(0));
            blit(newFBO);
            return newFBO;
        }
        
        function resizeDoubleFBO (target, w, h, internalFormat, format, type, param) {
            if (target.width === w && target.height === h)
                return target;
            target.read = resizeFBO(target.read, w, h, internalFormat, format, type, param);
            target.write = createFBO(w, h, internalFormat, format, type, param);
            target.width = w;
            target.height = h;
            target.texelSizeX = 1.0 / w;
            target.texelSizeY = 1.0 / h;
            return target;
        }
        
        if (config.SHADING)
            displayMaterial.enableShading();
        
        initFramebuffers();
        
        let lastUpdateTime = Date.now();
        let particleSpawnTimer = 0.0;
        let movementTimer = 0.0;
        let particleSpawnTimeSteps = [1.3, 2.3, 3.0, 3.4];
        let particleId = 0;
        let particleColors = ["#100605", "#120a08", "#0f0401"]
        let spawnAt = 0.02;
        let speed = 10;

        if (isMobile()) {
            spawnAt = 0.03;
            speed = 14;
        }

        initParticle(particles[0], particleId++, particleColors[0], canvas.width / 2, canvas.height);
        
        (function update() {
            const dt = calcDeltaTime();

            if (particleSpawnTimeSteps[0] >= particleSpawnTimer) {
                particleSpawnTimer += dt;
            }
            movementTimer += dt;

            if (movementTimer >= 0.02) {
                particles.forEach(particle => {
                    let posX = particle.texcoordX * canvas.width;
                    
                    let func = particle.id % 2 !== 0 ? Math.cos : Math.sin;
                    posX += func(particle.texcoordY * 30) * 14 * (1.1 - particle.texcoordY);

                    let posY = (1.0 - particle.texcoordY) * canvas.height - (1.2 - particle.texcoordY) * speed;

                    if (!particle.decaying &&
                        particle.texcoordY >= spawnAt &&
                        particleSpawnTimer >= particleSpawnTimeSteps[0]) {
                        particle.decaying = true;
                        
                        let newParticle = new Particle();
                        initParticle(
                            newParticle,
                            particleId++,
                            particleColors[particleId % particleColors.length],
                            canvas.width / 2,
                            canvas.height + particle.id % 2 * 150 - Math.random() * 100
                        );
                        particles.push(newParticle);

                        if (particleSpawnTimeSteps.length > 1) {
                            particleSpawnTimeSteps.splice(0,1);
                        }
                    }

                    if (particle.texcoordY < 0.8) {
                        moveParticle(particle, posX, posY);
                    } else {
                        particles.splice(particles.indexOf(particle), 1);
                    }
                });
                movementTimer = 0;
            }

            if (resizeCanvas())
                initFramebuffers();
            splatParticles();
            step(dt);
            render();
            requestAnimationFrame(update);
        })();
        
        function calcDeltaTime () {
            let now = Date.now();
            let dt = (now - lastUpdateTime) / 1000;
            dt = Math.min(dt, 0.016666);
            lastUpdateTime = now;
            return dt;
        }
        
        function resizeCanvas () {
            let width = scaleByPixelRatio(canvas.clientWidth);
            let height = scaleByPixelRatio(canvas.clientHeight);
            if (canvas.width !== width || canvas.height !== height) {
                canvas.width = width;
                canvas.height = height;
                return true;
            }
            return false;
        }
        
        function splatParticles () {
            particles.forEach(particle => {
                if (particle.moved) {
                    particle.moved = false;
                    let dx = particle.deltaX * config.SPLAT_FORCE;
                    let dy = particle.deltaY * config.SPLAT_FORCE;
                    splat(particle.texcoordX, particle.texcoordY, dx, dy, particle.color);
                }
            });
        }
        
        function step(dt) {
            gl.disable(gl.BLEND);
        
            curlProgram.bind();
            gl.uniform2f(curlProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
            gl.uniform1i(curlProgram.uniforms.uVelocity, velocity.read.attach(0));
            blit(curl);
        
            vorticityProgram.bind();
            gl.uniform2f(vorticityProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
            gl.uniform1i(vorticityProgram.uniforms.uVelocity, velocity.read.attach(0));
            gl.uniform1i(vorticityProgram.uniforms.uCurl, curl.attach(1));
            gl.uniform1f(vorticityProgram.uniforms.curl, config.CURL);
            gl.uniform1f(vorticityProgram.uniforms.dt, dt);
            blit(velocity.write);
            velocity.swap();
        
            divergenceProgram.bind();
            gl.uniform2f(divergenceProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
            gl.uniform1i(divergenceProgram.uniforms.uVelocity, velocity.read.attach(0));
            blit(divergence);
        
            clearProgram.bind();
            gl.uniform1i(clearProgram.uniforms.uTexture, pressure.read.attach(0));
            gl.uniform1f(clearProgram.uniforms.value, config.PRESSURE);
            blit(pressure.write);
            pressure.swap();
        
            pressureProgram.bind();
            gl.uniform2f(pressureProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
            gl.uniform1i(pressureProgram.uniforms.uDivergence, divergence.attach(0));
            for (let i = 0; i < config.PRESSURE_ITERATIONS; i++) {
                gl.uniform1i(pressureProgram.uniforms.uPressure, pressure.read.attach(1));
                blit(pressure.write);
                pressure.swap();
            }
        
            gradientSubtractProgram.bind();
            gl.uniform2f(gradientSubtractProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
            gl.uniform1i(gradientSubtractProgram.uniforms.uPressure, pressure.read.attach(0));
            gl.uniform1i(gradientSubtractProgram.uniforms.uVelocity, velocity.read.attach(1));
            blit(velocity.write);
            velocity.swap();
        
            advectionProgram.bind();
            gl.uniform2f(advectionProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
            if (!ext.supportLinearFiltering)
                gl.uniform2f(advectionProgram.uniforms.dyeTexelSize, velocity.texelSizeX, velocity.texelSizeY);
            let velocityId = velocity.read.attach(0);
            gl.uniform1i(advectionProgram.uniforms.uVelocity, velocityId);
            gl.uniform1i(advectionProgram.uniforms.uSource, velocityId);
            gl.uniform1f(advectionProgram.uniforms.dt, dt);
            gl.uniform1f(advectionProgram.uniforms.dissipation, config.VELOCITY_DISSIPATION);
            blit(velocity.write);
            velocity.swap();
        
            if (!ext.supportLinearFiltering)
                gl.uniform2f(advectionProgram.uniforms.dyeTexelSize, dye.texelSizeX, dye.texelSizeY);
            gl.uniform1i(advectionProgram.uniforms.uVelocity, velocity.read.attach(0));
            gl.uniform1i(advectionProgram.uniforms.uSource, dye.read.attach(1));
            gl.uniform1f(advectionProgram.uniforms.dissipation, config.DENSITY_DISSIPATION);
            blit(dye.write);
            dye.swap();
        }
        
        function render() {
            gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
            gl.enable(gl.BLEND);
        
            displayMaterial.bind();
            if (config.SHADING)
                gl.uniform2f(displayMaterial.uniforms.texelSize, 1.0 / gl.drawingBufferWidth, 1.0 / gl.drawingBufferHeight);
            gl.uniform1i(displayMaterial.uniforms.uTexture, dye.read.attach(0));
            blit();
        }

        function splat(x, y, dx, dy, color) {
            splatProgram.bind();
            gl.uniform1i(splatProgram.uniforms.uTarget, velocity.read.attach(0));
            gl.uniform1f(splatProgram.uniforms.aspectRatio, canvas.width / canvas.height);
            gl.uniform2f(splatProgram.uniforms.point, x, y);
            gl.uniform3f(splatProgram.uniforms.color, dx, dy, 0.0);
            gl.uniform1f(splatProgram.uniforms.radius, correctRadius(config.SPLAT_RADIUS / 100.0));
            blit(velocity.write);
            velocity.swap();
        
            gl.uniform1i(splatProgram.uniforms.uTarget, dye.read.attach(0));
            gl.uniform3f(splatProgram.uniforms.color, color.r, color.g, color.b);
            blit(dye.write);
            dye.swap();
        }
        
        function correctRadius(radius) {
            let aspectRatio = canvas.width / canvas.height;
            if (aspectRatio > 1)
                radius *= aspectRatio;
            return radius;
        }

        function initParticle(particle, id, color, posX, posY) {
            particle.id = id;
            particle.moved = false;
            particle.texcoordX = posX / canvas.width;
            particle.texcoordY = 1.0 - posY / canvas.height;
            particle.prevTexcoordX = particle.texcoordX;
            particle.prevTexcoordY = particle.texcoordY;
            particle.deltaX = 0;
            particle.deltaY = 0;
            particle.color = normalizeColor(hexToRgb(color));
        }
        
        function moveParticle(pointer, posX, posY) {
            pointer.prevTexcoordX = pointer.texcoordX;
            pointer.prevTexcoordY = pointer.texcoordY;
            pointer.texcoordX = posX / canvas.width;
            pointer.texcoordY = 1.0 - posY / canvas.height;
            pointer.deltaX = correctDeltaX(pointer.texcoordX - pointer.prevTexcoordX);
            pointer.deltaY = correctDeltaY(pointer.texcoordY - pointer.prevTexcoordY);
            pointer.moved = Math.abs(pointer.deltaX) > 0 || Math.abs(pointer.deltaY) > 0;
        }

        function correctDeltaX (delta) {
            let aspectRatio = canvas.width / canvas.height;
            if (aspectRatio < 1) delta *= aspectRatio;
            return delta;
        }
        
        function correctDeltaY (delta) {
            let aspectRatio = canvas.width / canvas.height;
            if (aspectRatio > 1) delta /= aspectRatio;
            return delta;
        }

        function hexToRgb(hex) {
            if (hex.charAt(0) === '#') {
                hex = hex.slice(1);
            }
        
            // If the hex code is shorthand (3 characters), convert it to full form (6 characters)
            if (hex.length === 3) {
                hex = hex.split('').map(function(char) {
                    return char + char;
                }).join('');
            }
        
            var r = parseInt(hex.slice(0, 2), 16);
            var g = parseInt(hex.slice(2, 4), 16);
            var b = parseInt(hex.slice(4, 6), 16);
        
            return { r: r, g: g, b: b };
        }
        
        function normalizeColor(input) {
            let output = {
                r: input.r / 255,
                g: input.g / 255,
                b: input.b / 255
            };
            return output;
        }
        
        function getResolution(resolution) {
            let aspectRatio = gl.drawingBufferWidth / gl.drawingBufferHeight;
            if (aspectRatio < 1)
                aspectRatio = 1.0 / aspectRatio;
        
            let min = Math.round(resolution);
            let max = Math.round(resolution * aspectRatio);
        
            if (gl.drawingBufferWidth > gl.drawingBufferHeight)
                return { width: max, height: min };
            else
                return { width: min, height: max };
        }
        
        function scaleByPixelRatio(input) {
            let pixelRatio = window.devicePixelRatio || 1;
            return Math.floor(input * pixelRatio);
        }
    }, [rendering]);

  return (
    <canvas ref={canvasRef} />
  );
}

export default Smoke;
