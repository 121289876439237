import logo from './logo.svg';
import styles from './Layout.module.css';
import Smoke from './Smoke';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useRef } from 'react';
import ContentBox from './ContentBox';

function Layout() {
  const headerRef = useRef(null);
  const infoRef = useRef(null);
  const contactRef = useRef(null);
  const observeTargetRef = useRef(null);
  const preventScrollLoopRef = useRef(false);
  const observing = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (observing.current) return;

    observing.current = true;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (observeTargetRef.current) {
          if (observeTargetRef.current === entry.target && entry.isIntersecting) {
            observeTargetRef.current = null;
          }
        } else if (entry.isIntersecting) {
          let navTarget = "/";
          if (entry.target === infoRef.current) navTarget = "/info";
          else if (entry.target === contactRef.current) navTarget = "/contact";
          navigate(navTarget);
        }
      });
    });

    let observingThrottle = false;

    const observe = (e) => {
      e.preventDefault();
      preventScrollLoopRef.current = true;

      if (observingThrottle) return;
      observingThrottle = true;

      observer.observe(headerRef.current);
      observer.observe(infoRef.current);
      observer.observe(contactRef.current);
    };

    const unobserve = (e) => {
      e.preventDefault();
      preventScrollLoopRef.current = false;

      if (!observingThrottle) return;
      observingThrottle = false;

      observer.unobserve(headerRef.current);
      observer.unobserve(infoRef.current);
      observer.unobserve(contactRef.current);
    };

    window.addEventListener("scroll", observe);
    window.addEventListener("scrollend", unobserve);
  }, [navigate]);

  useEffect(() => {
    if (!preventScrollLoopRef.current) {
      const id = location.pathname.split("/").pop();

      window.requestAnimationFrame(() => {
        const element = (() => {
            switch(id) {
                case "info": return infoRef.current;
                case "contact": return contactRef.current;
                default: return null;
            }
        })(); 
        window.scrollTo({ top: element ? element.offsetTop : 0, behavior: "smooth" })
      });
    }
  }, [location]);

  return (
    <div className={styles.layout}>
      <Smoke />
      <img src={logo} className={styles.logo} alt="logo" />
      <div className={styles.content}>
        <header className={styles.header} ref={headerRef}>
          <div className={styles.companyTitle}>
            <div className={styles.companyName}>VELES</div>
            <div className={styles.companySubtitle}>media solutions</div>
          </div>
          <div className={styles.menu}>
            <Link to="/info" onClick={() => observeTargetRef.current = infoRef.current}>info</Link>
            <Link to="/contact" onClick={() => observeTargetRef.current = contactRef.current}>contact</Link>
          </div>
        </header>
        <ContentBox ref={infoRef}>
            <p>We offer:</p>
            <ul>
                <li><b>Software Design</b>: Customized software solutions tailored to your specific needs.</li>
                <li><b>Software Consulting and Engineering</b>: Expert guidance and engineering services to optimize your projects.</li>
                <li><b>Graphic Design</b>: Creative and professional design services to enhance your brand.</li>
                <li><b>Photography</b>: High-quality photography services to capture and promote your vision.</li>
            </ul>
        </ContentBox>
        <ContentBox style={{backgroundColor: "#ffe1d8ee"}} ref={contactRef}>
            <p>Contact:</p>
            <ul>
                <li><b>e-mail</b>: <a href="mailto:contact@veles.media">contact@veles.media</a></li>
            </ul>
        </ContentBox>
      </div>
    </div>
  );
}

export default Layout;