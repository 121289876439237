import styles from './ContentBox.module.css';
import { forwardRef } from 'react';

const ContentBox = forwardRef(function ContentBox({style, children}, ref) {
  return (
    <div className={styles.container} style={style} ref={ref}>
        <div className={styles.content}>{children}</div>
    </div>
  );
});

export default ContentBox;
